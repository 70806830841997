<template>
    <div>
        <slot name="top"></slot>
        <div>
            <SelectOptionDocument :module="module" :documentId="documents" :rentId="rentId" :movimentId="movimentId"
                :invoiceId="invoiceId" :listBillet="listBillet" :periodRentId="periodRentId"
                :payableReceivableId="payableReceivableId" :orderServiceId="orderServiceId" v-show="nameDocument == ''"
                v-model="documents">
                <slot></slot>
            </SelectOptionDocument>

            <DocumentPreview v-if="nameDocument == 'preview'" :documentId="documents" :rentId="rentId"
                :invoiceId="invoiceId" :movimentId="movimentId" :periodRentId="periodRentId"
                :payableReceivableId="payableReceivableId" :orderServiceId="orderServiceId" :canModifyDocument="canModifyDocument"
                :back="back" />

            <SendMail v-if="nameDocument == 'mail'" :module="module" :documents="documents" :listBillet="listBillet"
                :rentId="rentId" :movimentId="movimentId" :invoiceId="invoiceId" :periodRentId="periodRentId"
                :payableReceivableId="payableReceivableId" :orderServiceId="orderServiceId" :back="back" />

            <Link v-if="nameDocument == 'link'" :module="module" :documents="documents" :rentId="rentId"
                :movimentId="movimentId" :invoiceId="invoiceId" :periodRentId="periodRentId"
                :payableReceivableId="payableReceivableId" :orderServiceId="orderServiceId" :back="back" />

            <div v-if="nameDocument == 'billet'">
                <div class="div-btn-back">
                    <Button _key="back" title="Voltar" type="info" classIcon="fa-solid fa-arrow-left" size="small"
                        :clicked="back" />
                </div>
                <PDFBillet :selected="listIds" :showButtonLink="false" :showButtonEmail="false" />
            </div>

            <SendSignature v-if="nameDocument == 'signature'" :module="module" :documents="documents" :rentId="rentId"
                :movimentId="movimentId" :invoiceId="invoiceId" :periodRentId="periodRentId"
                :payableReceivableId="payableReceivableId" :orderServiceId="orderServiceId" :back="back" />

        </div>
    </div>
</template>

<script>



import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import SelectOptionDocument from "../../adm/document/SelectOptionDocument.vue";
import DocumentPreview from "../../adm/document/DocumentPreview.vue";
import SendMail from './SendMail.vue'
import Link from './Link.vue'
import PDFBillet from '../../billing/billet/PDFBillet.vue'
import SendSignature from './SendSignature.vue'

import { mapGetters, mapMutations } from "vuex";

export default {
    name: "PrintDetail",
    components: {
        Button,
        SelectOptionDocument,
        DocumentPreview, SendMail, Link, PDFBillet, SendSignature
    },
    props: {
        module: String,
        rentId: String,
        periodRentId: String,
        movimentId: String,
        invoiceId: String,
        payableReceivableId: String,
        orderServiceId: String,
        canModifyDocument: Boolean,
        listBillet: {
            type: Array,
            default: []
        },
    },
    data() {
        return {
            nameDocument: "",
            documents: "",
        };
    },
    computed: {
        ...mapGetters("generic", ["event"]),
        listIds() {
            let listIds = [];
            this.listBillet.forEach(billet => {
                listIds.push(billet.payableReceivableId);
            });
            return listIds;
        }
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "removeLoading"]),
        print() {
            this.openModal("print");
            this.removeLoading(["btnPrint"]);
        },
        back() {
            this.addEvent({ name: "back" });
            this.removeLoading(["back"]);
        }
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "btnClickedNext") {
                    if (event.data.type == "view") this.nameDocument = 'preview';
                    if (event.data.type == "mail") this.nameDocument = 'mail';
                    if (event.data.type == "link") this.nameDocument = 'link';
                    if (event.data.type == "billet") this.nameDocument = 'billet';
                    if (event.data.type == "signature") this.nameDocument = 'signature';
                }

                if (event.name == "emailSent" || event.name == "signatureSent") this.back();
                if (event.name == "back") this.nameDocument = '';

            },
            deep: true,
        },
    },
};
</script>
<style scoped>
.div-btn-back {
    margin-bottom: 13px;
}
</style>